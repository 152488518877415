const props = [
  'scroll',
  'mouseenter',
  'mouseleave',
  'mousemove',
  'mouseover',
  'mouseout',
  'mousewheel',
  'wheel',
  'pointermove',
  'pointerleave',
];

(window as any).__zone_symbol__UNPATCHED_EVENTS = props;
const window1 = window as any;
window1.__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
// black list scroll event handler for onProp
const targets = [HTMLCanvasElement.prototype];
window1.__Zone_ignore_on_properties = [];
targets.forEach(function (target) {
  window1.__Zone_ignore_on_properties.push({
    target: target,
    ignoreProperties: props,
  });
});
